const fetch = require('node-fetch');

const endpoint = 'https://us-central1-samantha-nutt.cloudfunctions.net/content/';

class airtableBackend {

    getProjects() {
        return new Promise((resolve, reject) => {
            fetch(endpoint+"projects/")
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err));
        });
    }
    
    getAbout() {
        return new Promise((resolve, reject) => {
            fetch(endpoint+"about/")
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err));
        });
    }
    
    getArchive() {
        return new Promise((resolve, reject) => {
            fetch(endpoint+"archive/")
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err));
        });
    }
};

export default new airtableBackend;