import React from 'react';

import useWindowDimensions from './useWindowDimensions';

import MarkdownView from 'react-showdown';
import { Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import './Project.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';

export default function Project(fields) {
    const { height, width } = useWindowDimensions();
    const BREAKPOINT_MOBILE = 736;

    function handleClick(e) {
        e.preventDefault();
        var imgWidth = e.currentTarget.offsetWidth / 2;
        var posX = e.clientX;

        if (posX > imgWidth) {
            if (e.target.nextElementSibling) {
                e.target.classList.add("hidden");
                e.target.nextElementSibling.classList.remove("hidden");
            } else {
                var siblings = e.target.parentNode.childNodes;
                for (var i = 0; i < siblings.length; i++) {
                    if (siblings[i].name = "image-0") {
                        e.target.classList.add("hidden");
                        siblings[i].classList.remove("hidden");
                        break;
                    }
                }
            }
        } else {
            if (e.target.previousElementSibling) {
                e.target.classList.add("hidden");
                e.target.previousElementSibling.classList.remove("hidden");
            } else {
                var siblings = e.target.parentNode.childNodes;

                for (var i = 0; i < siblings.length; i++) {
                    if (siblings[i].getAttribute('name') === "image-" + String(siblings.length - 1)) {
                        e.target.classList.add("hidden");
                        siblings[i].classList.remove("hidden");
                        break;
                    }
                }
            }
        }

    }

    function onMouseOver(e) {
        e.preventDefault();
        // if (width > BREAKPOINT_MOBILE) {
        const imageAmount = e.currentTarget.getAttribute('data-amount');
        if (imageAmount > 1) {
            var imgWidth = e.currentTarget.offsetWidth / 2;
            var posX = e.clientX;
            if (posX > imgWidth) {
                e.target.parentNode.style.cursor = "url('./pfeilumrandedR.png'), auto";
            } else {
                e.target.parentNode.style.cursor = "url('./pfeilumrandedL.png'), auto";
            }
        }
        // }
    }

    function onImageSmallLoad(e) {
        e.target.parentElement.classList.add("noBrg");
        e.target.classList.remove("hidden");
    }
    async function onImageLargeLoad(e) {
        e.persist()
        e.target.nextElementSibling.classList.add("removeBlur");
        await sleep(200);
        e.target.nextElementSibling.remove();
        e.target.classList.remove("hidden");
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    var { Description, Year, Client, ShareOfWork, Images } = fields.fields;
    if (ShareOfWork !== undefined) {
        ShareOfWork = ShareOfWork.join(', ');
    }
    var images = [];
    var imagesSwipeable = [];
    var iImages = 0;
    var clsName = "";
    Images.map(image => {
        if (iImages == 0) {
            clsName = "progressiveImg";
        } else {
            clsName = "progressiveImg hidden";
        }

        images.push(
            Images[iImages].type.includes("image") ?
                <div name={`image-${iImages}`}
                    onClick={handleClick}
                    className={clsName}
                    key={image.id}>
                    <img
                        src={Images[iImages].thumbnails.full.url}
                        onLoad={onImageLargeLoad}
                        alt={image.filename}
                        className="hidden"
                    />
                    <img
                        src={Images[iImages].thumbnails.small.url}
                        onLoad={onImageSmallLoad}
                        alt={image.filename}
                        className="hidden"
                        style={{ Width: '100%', Height: '100%', filter: 'blur(39px)' }}
                    />
                </div>
                :
                <div name={`image-${iImages}`}
                    onClick={handleClick}
                    className={clsName}
                    key={image.id}>
                    <video>
                        <source
                            src={Images[iImages].url}
                            type="video/mp4"
                            alt={image.filename}
                            className="hidden"
                        />
                    </video>
                </div>

        );
        imagesSwipeable.push(
            Images[iImages].type.includes("image") ?
                <SwiperSlide>
                    <div name={`image-${iImages}`}
                        onClick={handleClick}
                        key={image.id}
                        style={{ height: Images[0].height >= Images[0].width ? '60vw' : Images[0].height * ((width - 20) / Images[0].width) + 'px' }}
                    >
                        <img
                            src={Images[iImages].thumbnails.large.url}
                            alt={image.filename}
                        />
                    </div>
                </SwiperSlide>
                :
                <SwiperSlide>
                    <div name={`image-${iImages}`}
                        onClick={handleClick}
                        key={image.id}
                        style={{ height: Images[0].height >= Images[0].width ? '60vw' : Images[0].height * ((width - 20) / Images[0].width) + 'px' }}
                    >
                        <video>
                            <source
                                src={Images[iImages].url}
                                type="video/mp4"
                                alt={image.filename}
                                className="hidden"
                            />
                        </video>
                    </div>
                </SwiperSlide>
        );

        iImages++;
    });
    return (
        <div className="project">
            <div className="image" data-amount={images.length} onMouseMove={onMouseOver}>
                {width > BREAKPOINT_MOBILE ?
                    images
                    :
                    <Swiper
                        modules={[Scrollbar, Pagination]}
                        // slidesPerView={1}
                        pagination={{ clickable: true }}
                    // scrollbar={{
                    //     hide: true,
                    //     draggable: true,
                    //   }}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {imagesSwipeable}
                    </Swiper>
                }
            </div>



            <div className="text">
                <div className="row">
                    <h3 className="heading basetext">Client:</h3>
                    <p className="content basetext">{Client}</p>
                </div>

                <div className="row">
                    <h3 className="heading basetext">Description:</h3>
                    <p className="content basetext">
                        <MarkdownView markdown={Description} />
                    </p>
                </div>

                {Year !== undefined ?
                    <div className="row">
                        <h3 className="heading basetext">Year:</h3>
                        <p className="content basetext">{Year}</p>
                    </div>
                    : null}

                {/* <div className="row">
                    <h3 className="heading basetext">Share of Work:</h3>
                    <p className="content basetext">{ShareOfWork}</p>
                </div> */}

            </div>
        </div>
    );
}