import React from 'react';

import airtableBackend from '../airtableBackend';
import './Archive.css';
import LazyLoad from 'react-lazyload';

function Images(fields) {
    // console.log(fields);
    var { Images } = fields.fields;
    var allImages = [];
    Images.map(image => {
        // console.log(image.thumbnails.large.url);
        allImages.push(<LazyLoad key={image.id} offset={100}><img key={image.id} src={image.thumbnails.large.url}/></LazyLoad>);
    });
    return (
        <div key={fields.id} className="imageRow">{allImages}</div>
    );
}

export default class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = { projects: [] };
    }
    componentDidMount() {
        airtableBackend.getProjects()
        .then((value) => {
            this.setState({ projects: value })
        }).catch((err) => {
            console.log(err)
        });

        // base('Projects').select({
        //     sort: [{ field: 'Year', direction: 'desc' }, { field: 'Client', direction: 'desc' }],
        //     maxRecords: 100,
        //     view: "all"
        // }).firstPage().then(projects => {
        //     this.setState({ projects: projects })
        // });
    }
    render() {
        return (
            <div className="archive">
                {this.state.projects.map(project => {
                    var res;
                    var client=project.fields.Client
                    var year=project.fields.Year
                    res = [<p key={client} className="archiveHeading basetext">{client} - {year}</p>,<Images key={project.id} fields={project.fields} />]
                    return res
                }
                )}
            </div>
        );
    }
}