import React from 'react';

import airtableBackend from '../airtableBackend';
import './About.css';

export default class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = { about: {} };
    }

    componentDidMount() {
        airtableBackend.getAbout()
        .then((value) => {
            var about = {};
            value.map(aboutItem => {
                var field = aboutItem.fields.Field;
                var text = aboutItem.fields.Text;
                about[field] = text;
                
            });
            
            this.setState({ about: about });
        }).catch((err) => {
            console.log(err)
        });
    }

    render() {
        return (
            <div style={{height: '100vh', width: '100%'}}>
            <div className="aboutContainer">
                {this.state.about.About !== undefined  ?
                    <div>
                        <div className="row">
                            <h3 className="heading basetext ">About</h3>
                            <p className="content basetext">{this.state.about.About}</p>
                        </div>
                        {/* <div className="row">
                            <h3 className="heading basetext">Contact</h3>
                            <p className="content basetext">{this.state.about.Contact}</p>
                        </div> */}
                        <div className="row">
                            <h3 className="heading basetext">E-mail</h3>
                            <p className="content basetext"><a href={"mailto:"+this.state.about.Email}>{this.state.about.Email}</a></p>
                        </div>
                        <div className="row">
                            <h3 className="heading basetext">Tumblr</h3>
                            <p className="content basetext"><a href={'https://'+this.state.about.Tumblr} target="_blank">{this.state.about.Tumblr}</a></p>
                        </div>
                        <div className="row">
                            <h3 className="heading basetext">Instagram</h3>
                            <p className="content basetext"><a href={'https://instagram.com/'+this.state.about.Instagram.replace('@', '')} target="_blank">{this.state.about.Instagram}</a></p>
                        </div>
                    </div>
                : null }
            </div>
            </div>
        );
    }
}