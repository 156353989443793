import React from 'react';

import airtableBackend from '../airtableBackend';
import Project from './Project';

export default class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = { projects: [] };
    }
    componentDidMount() {
        airtableBackend.getProjects()
            .then((value) => {
                this.setState({ projects: value })
            }).catch((err) => {
                console.log(err)
            });
    }
    render() {
        return (
            <div>
                {/* <Intro /> */}
                <div id="firstProject"></div>
                {this.state.projects.map((project, i) => {
                    var res;
                    res = <Project key={project.id} fields={project.fields} />
                    return res
                }
                )}
            </div>

        );
    }
}