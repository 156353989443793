import React from 'react';

import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Projects, Archive, About } from "./Component";


function App() {
  return (
    <Router className="App">
      <header>
        <Link to="/overview">Overview</Link>
        <Link to="/" className="linkHome">Samantha Nutt</Link>
        <Link to="/about">About</Link>
      </header>
    

      <Route path="/" exact component={Projects} />
      <Route path="/overview" component={Archive} />
      <Route path="/about" component={About} />

    </Router>
  );
}

export default App;
